// third-party
import { configureStore } from '@reduxjs/toolkit'
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux'
import { merklebaseAPI } from './api/merklebaseAPI'
import { rtkQueryErrorLogger } from './api/errorHandler'

// project import
import reducers from './reducers'

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([merklebaseAPI.middleware, rtkQueryErrorLogger]),
})

const { dispatch } = store

const useDispatch = () => useAppDispatch()
const useSelector = useAppSelector

export { store, dispatch, useSelector, useDispatch }
