import jwtDecode from 'jwt-decode'
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'

export const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
  ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || '',
})

export const setSessionToken = (accessToken = null, refreshToken = null) => {
  if (accessToken) {
    localStorage.setItem('serviceToken', JSON.stringify({ accessToken, refreshToken }))
  } else {
    localStorage.removeItem('serviceToken')
  }
}

export const parseJWTToken = (token) => JSON.parse(Buffer.from(token.split('.')[1], 'base64'))

export const isTokenValid = (accessToken) => {
  if (!accessToken) {
    return false
  }
  const decoded = jwtDecode(accessToken)
  if (decoded.exp < Date.now() / 1000) {
    return false
  }

  return true
}

export const refreshToken = async (accessToken) => {
  const decoded = jwtDecode(accessToken)

  const usr = new CognitoUser({
    Username: decoded.username,
    Pool: userPool,
  })

  const refreshResult = await new Promise((res) =>
    usr.getSession((err, session) => {
      if (err) {
        console.error(err)
        usr.signOut()
        window.location.reload()
        res(false)
      }
      usr.refreshSession(session.getRefreshToken(), (err, session) => {
        if (err) {
          console.error(err)
          usr.signOut()
          window.location.reload()
          res(false)
        }

        setSessionToken(
          session.getAccessToken().getJwtToken(),
          session.getRefreshToken().getToken()
        )

        res(true)
      })
    })
  )

  return refreshResult
}

export const isUserSessionAvailable = async (accessToken) => {
  if (!accessToken) return false
  const decoded = jwtDecode(accessToken)

  const usr = new CognitoUser({
    Username: decoded.username,
    Pool: userPool,
  })

  const res = await new Promise((res) =>
    usr.getSession((err) => {
      if (err) {
        res(false)
      }
      res(true)
    })
  )

  return res
}
