import PropTypes from 'prop-types'
import { createContext, useEffect, useMemo, useState } from 'react'

// third-party
import { CognitoUser, AuthenticationDetails, CognitoUserPool } from 'amazon-cognito-identity-js'

// project imports
import Loader from 'components/Loader'
import LOGIN_STEPS from 'enums/Login'
import { useGetUserQuery } from 'store/api/merklebaseAPI'
import {
  isTokenValid,
  refreshToken,
  parseJWTToken,
  setSessionToken,
  isUserSessionAvailable,
} from 'utils/tokens'
import { datadogRum } from '@datadog/browser-rum'
import { mapUser } from 'utils/mappers/user'
import { organizationsTypes } from 'merklebase-utils/enums'

export const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
  ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID || '',
})

// ==============================|| AWS COGNITO - CONTEXT & PROVIDER ||============================== //

const AWSCognitoContext = createContext(null)

export const AWSCognitoProvider = ({ children }) => {
  const [loginSteps, setLoginSteps] = useState(LOGIN_STEPS.USER_PASSWORD)
  const [associateMFACode, setAssociateMFACode] = useState(null)
  const [cognitoUser, setCognitoUser] = useState(null)
  const [associateVerificationCode, setAssociateVerificationCode] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const [userId, setUserId] = useState(null)
  const { data: user } = useGetUserQuery(userId, {
    skip: !userId,
  })

  useEffect(() => {
    if (!user?.id) return

    datadogRum.setUser({
      id: user?.id,
      name: user?.first_name + ' ' + user?.last_name,
      email: user?.email,
      organizationName: user?.organization_name,
      organizationId: user?.organization_id,
    })
  }, [
    user?.id,
    user?.first_name,
    user?.last_name,
    user?.email,
    user?.organization_id,
    user?.organization_name,
  ])

  const logInUser = (accessToken) => {
    setUserId(accessToken.sub)
    setIsLoggedIn(true)
    setIsInitialized(true)
  }

  const logOutUser = () => {
    setSessionToken()
    setIsLoggedIn(false)
    setIsInitialized(true)
  }

  const userTenant = useMemo(() => user?.organization_name, [user])

  useEffect(() => {
    const init = async () => {
      try {
        const { accessToken = null } = JSON.parse(window.localStorage.getItem('serviceToken')) || {}

        // Get username from serviceToken jwt
        const localStorageCognitoUser = accessToken ? parseJWTToken(accessToken) : null

        if ((await isUserSessionAvailable(accessToken)) && isTokenValid(accessToken)) {
          logInUser(localStorageCognitoUser)
        } else {
          if (accessToken && (await refreshToken(accessToken))) {
            logInUser(localStorageCognitoUser)
          } else {
            logOutUser()
          }
        }
      } catch (err) {
        console.error(err)
        logOutUser()
      }
    }

    init()
  }, [])

  const login = async (username, password) => {
    const usr = new CognitoUser({
      Username: username,
      Pool: userPool,
    })

    setCognitoUser(usr)

    const authData = new AuthenticationDetails({
      Username: username,
      Password: password,
    })
    const result = await new Promise((resolve, reject) => {
      usr.authenticateUser(authData, {
        onSuccess: (data) => {
          resolve(data)
        },
        onFailure: (error) => {
          reject(error)
        },
        newPasswordRequired: () => {
          resolve()
          setLoginSteps(LOGIN_STEPS.NEW_PASSWORD)
        },
        mfaRequired: () => {
          setLoginSteps(LOGIN_STEPS.MFA)
          resolve()
        },
        totpRequired: () => {
          setLoginSteps(LOGIN_STEPS.MFA)
          resolve()
        },
        mfaSetup: async () =>
          usr.associateSoftwareToken({
            onFailure: (error) => {
              reject(error)
            },
            associateSecretCode: (code) => {
              setAssociateMFACode({
                username: usr.username,
                code,
              })
              setLoginSteps(LOGIN_STEPS.SAVE_MFA)
              resolve()
            },
          }),
      })
    })

    return result
  }

  const newPassword = async (password) => {
    const result = await new Promise((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(
        password,
        {},
        {
          onSuccess: () => {
            resolve()
          },
          onFailure: (error) => reject(error),
          newPasswordRequired: () => {
            setLoginSteps(LOGIN_STEPS.NEW_PASSWORD)
            resolve()
          },
          mfaRequired: () => {
            setLoginSteps(LOGIN_STEPS.MFA)
            resolve()
          },
          totpRequired: () => {
            setLoginSteps(LOGIN_STEPS.MFA)
            resolve()
          },
          mfaSetup: () => {
            setLoginSteps(LOGIN_STEPS.SAVE_MFA)
            resolve()
          },
        }
      )
    })

    return result
  }

  const saveMFA = async (code) => {
    const result = await new Promise((resolve, reject) => {
      cognitoUser.verifySoftwareToken(code, 'My TOTP device', {
        onSuccess: (session) => {
          setSessionToken(
            session.getAccessToken().getJwtToken(),
            session.getRefreshToken().getToken()
          )
          logInUser(session.getAccessToken().payload)
          resolve()
        },
        onFailure: (error) => reject(error),
        newPasswordRequired: () => {
          setLoginSteps(LOGIN_STEPS.NEW_PASSWORD)
          resolve()
        },
        mfaRequired: () => {
          setLoginSteps(LOGIN_STEPS.MFA)
          resolve()
        },
        totpRequired: () => {
          setLoginSteps(LOGIN_STEPS.MFA)
          resolve()
        },
        mfaSetup: () => {
          setLoginSteps(LOGIN_STEPS.SAVE_MFA)
          resolve()
        },
      })
    })

    return result
  }

  const setMFA = async (code) => {
    const result = await new Promise((resolve, reject) => {
      cognitoUser.sendMFACode(
        code,
        {
          onSuccess: (session) => {
            setSessionToken(
              session.getAccessToken().getJwtToken(),
              session.getRefreshToken().getToken()
            )
            logInUser(session.getAccessToken().payload)
            resolve()
          },
          onFailure: (error) => reject(error),
          newPasswordRequired: () => {
            setLoginSteps(LOGIN_STEPS.NEW_PASSWORD)
            resolve()
          },
          mfaRequired: () => {
            setLoginSteps(LOGIN_STEPS.MFA)
            resolve()
          },
          totpRequired: () => {
            setLoginSteps(LOGIN_STEPS.MFA)
            resolve()
          },
          mfaSetup: () => {
            setLoginSteps(LOGIN_STEPS.SAVE_MFA)
            resolve()
          },
        },
        'SOFTWARE_TOKEN_MFA'
      )
    })

    return result
  }

  const logout = () => {
    setSessionToken(null)
    setLoginSteps(LOGIN_STEPS.USER_PASSWORD)
    setIsLoggedIn(false)
    const loggedInUser = userPool.getCurrentUser()
    if (loggedInUser) {
      loggedInUser.signOut()
    }
  }

  const sendForgotPasswordVerificationCode = async (username) => {
    const user = new CognitoUser({
      Username: username,
      Pool: userPool,
    })

    setCognitoUser(user)

    const result = await new Promise((resolve, reject) => {
      user.forgotPassword({
        onSuccess: (d) => {
          setLoginSteps(LOGIN_STEPS.VERIFICATION_CODE)
        },
        onFailure: (error) => {
          reject(error)
        },
      })
    })

    return result
  }

  const verifyForgotPasswordCode = async (code) => {
    setAssociateVerificationCode(code)
    setLoginSteps(LOGIN_STEPS.NEW_PASSWORD)
  }

  const resetPassword = async (newPassword) => {
    const result = await new Promise((resolve, reject) => {
      if (associateVerificationCode) {
        cognitoUser.confirmPassword(associateVerificationCode, newPassword, {
          onSuccess: () => {
            setLoginSteps(LOGIN_STEPS.USER_PASSWORD)
            resolve()
          },
          onFailure: (error) => {
            reject(error)
          },
        })
      } else {
        cognitoUser.completeNewPasswordChallenge(
          newPassword,
          {},
          {
            onSuccess: () => {
              setLoginSteps(LOGIN_STEPS.USER_PASSWORD)
              resolve()
            },
            onFailure: (error) => reject(error),
            mfaRequired: () => {
              setLoginSteps(LOGIN_STEPS.MFA)
              resolve()
            },
            mfaSetup: () =>
              cognitoUser.associateSoftwareToken({
                onFailure: (error) => {
                  reject(error)
                },
                associateSecretCode: (code) => {
                  setAssociateMFACode({
                    username: cognitoUser.username,
                    code,
                  })
                  setLoginSteps(LOGIN_STEPS.SAVE_MFA)
                  resolve()
                },
              }),
          }
        )
      }
    })

    return result
  }

  const updateProfile = () => {}

  if (isInitialized !== undefined && !isInitialized) {
    return <Loader />
  }

  return (
    <AWSCognitoContext.Provider
      value={{
        user: user ? mapUser({ user }) : undefined,
        login,
        isInitialized,
        newPassword,
        logout,
        saveMFA,
        setMFA,
        associateMFACode,
        resetPassword,
        sendForgotPasswordVerificationCode,
        verifyForgotPasswordCode,
        loginSteps,
        setLoginSteps,
        isLoggedIn,
        userTenant,
        isBookingOrganization: user?.organization_type_id === organizationsTypes.booking,
        setIsLoggedIn,
        updateProfile,
        loginWithAccessToken: logInUser,
      }}
    >
      {children}
    </AWSCognitoContext.Provider>
  )
}

AWSCognitoProvider.propTypes = {
  children: PropTypes.node,
}

export default AWSCognitoContext
